import React from 'react'
import Avatar from '../images/avatar-rina'

export default () =>
  <div className="posts posts-person" id="rina">
    <h1 className="content-subhead">Project Manager, Artist, Dev Wrangler</h1>
    <section className="post">
      <header className="post-header">
        <Avatar className="post-avatar" alt="Rina Transom" />
        <h2 className="post-title">Rina Transom</h2>
      </header>

      <div className="post-description">
        <p>Rina concentrates on managing projects in an agile environment for online applications, while liaising with
        stakeholders to deliver outstanding products. A background in graphic design and a few years of hands-on web
        development has given her a good base for understanding all parts of the development process. She enjoys focusing
        on User Experience and making custom development user friendly and appealing.</p>
      </div>

      <div className="post-description">
        <div className="pure-g">
          <div className="pure-u-1 pure-u-md-1-2">
            <ul className="fa-ul bullet-list">
              <li><i className="fa-li fa fa-lg fa-star" />Specialises in managing agile teams, as well as creating product backlogs, user stories, acceptance criteria and task breakdowns.</li>
              <li><i className="fa-li fa fa-lg fa-heart" />Enjoys scoping, planning &amp; wireframing custom projects.</li>
              <li><i className="fa-li fa fa-lg fa-lightbulb-o" />Creative thinker with analytical and problem-solving skills.</li>
              <li><i className="fa-li fa fa-lg fa-paint-brush" />Paints as Rina Botha; work can be found at <a href="https://rinabotha.com">rinabotha.com</a> and sometimes on <a href="https://twitter.com/rinabotha">twitter</a>.</li>
              <li><i className="fa-li fa fa-lg fa-cloud" /><del>Dreams of finishing</del> <ins>Has finished</ins> her first illustrated children's book, <b>Adventure Jock and the treasure map</b>. You can buy this self-published title online at <a href="https://rinabotha.com#isbn-9780473460327">rinabotha.com</a>, in both print or ebook formats.</li>
            </ul>
          </div>
          <div className="pure-u-1 pure-u-md-1-2">
            <div className="contact">
              <h3>Rina is…</h3>
              <p>
                {/*<i className="fa fa-fw fa-lg fa-toggle-on brnd-toggle-on" />Available for contract work.<br />*/}
                <i className="fa fa-fw fa-lg fa-envelope-o brnd-default" /><a href="mailto:rina@singular.co.nz">rina@singular.co.nz</a><br />
                <i className="fa fa-fw fa-lg fa-skype brnd-skype" /><a href="skype:rinabotha?call">rinabotha</a><br />
                <i className="fa fa-fw fa-lg fa-twitter brnd-twitter" /><a href="https://twitter.com/rinabotha">@rinabotha</a><br />
                <i className="fa fa-fw fa-lg fa-linkedin-square brnd-linkedin" /><a href="https://nz.linkedin.com/in/rinabotha">view her profile on linkedIn</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
