import React from 'react'
//import { Link } from 'gatsby'

import Layout from '../components/layout'
import Home from '../components/home'
//import Image from '../components/image'
import Helmet from 'react-helmet'

const HomePage = () => (
  <Layout>
    <Helmet
      title="Singular &ndash; Online development &amp; project planning, Auckland, New Zealand"
    />
    <Home />
  </Layout>
)

export default HomePage
