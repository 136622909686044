import React from 'react'

export default () =>
  <div className="posts posts-person" id="dave">
    <h1 className="content-subhead">Web Developer &amp; Special Projects</h1>
    <section className="post">
      <header className="post-header">
        <img className="post-avatar" alt="Dave Transom" height="150" width="150" src="https://s.gravatar.com/avatar/93866681f80340ca56c049546c5e0926?s=150" />
        <h2 className="post-title">Dave Transom</h2>

        {/*
          <ul className="contact-options fa-ul">
            <li><i className="fa fa-li fa-lg fa-skype" style="color: #00aff0;" /><a href="skype:dave.transom?call">dave.transom</a></li>
            <li><i className="fa fa-li fa-lg fa-phone" style="color: #777;" />+64 9 889 0794</li>
            <li><i className="fa fa-li fa-lg fa-envelope-o" style="color: #777;" /><a href="mailto:dave@singular.co.nz">dave@singular.co.nz</a></li>
            <li><i className="fa fa-li fa-lg fa-twitter" style="color: #55acee;" /><a href="https://twitter.com/davetransom">@davetransom</a></li>
          </ul>
        */}
      </header>

      <div className="post-description">
        <p>Dave is a full-stack web developer based in Auckland, New Zealand. He spends his days coding in C# and
        Javascript, works mostly in the .NET space, and is generally open to other frameworks and technologies.</p>
      </div>

      <div className="post-description">
        <div className="pure-g">
            <div className="pure-u-1 pure-u-md-1-2">
              <ul className="fa-ul bullet-list">
                <li><i className="fa-li fa fa-lg fa-heart" />Loves: HTTP APIs, JSON, React, Quality Gear, Good Food &amp; Craft Beer.</li>
                <li><i className="fa-li fa fa-lg fa-thumbs-down" />Dislikes: Web Forms, Offshore Cold Callers.</li>
                <li><i className="fa-li fa fa-lg fa-road" />Works with a select few clients doing web development and special projects.</li>
                <li><i className="fa-li fa fa-lg fa-twitter" />Can be found lurking on <a href="https://twitter.com/davetransom">twitter</a> and occasionally <a href="/blog">blogging</a>.</li>
                <li><i className="fa-li fa fa-lg fa-share-alt" />Also claims some <a href="http://whatsgoodforyou.singular.co.nz/author/davio/">recipes</a> and a few demo <a href="https://hexymusic.com/download/">recordings</a> from the late nineties (<a href="https://soundcloud.com/davetransom" title="Dave's soundcloud"><i className="fa fa-soundcloud brnd-soundcloud" /></a>).</li>
              </ul>
            </div>
            <div className="pure-u-1 pure-u-md-1-2">
              <div className="contact">
                <h3>Dave can be found on…</h3>
                <p>
                  <i className="fa fa-fw fa-lg fa-skype brnd-skype" /><a href="skype:dave.transom?call">dave.transom</a><br />
                  {/*<i className="fa fa-fw fa-lg fa-phone brnd-default" />+64 9 889 0794<br />*/}
                  <i className="fa fa-fw fa-lg fa-envelope-o brnd-default" /><a href="mailto:dave@singular.co.nz">dave@singular.co.nz</a><br />
                  <i className="fa fa-fw fa-lg fa-twitter brnd-twitter" /><a href="https://twitter.com/davetransom">@davetransom</a><br />
                  <i className="fa fa-fw fa-lg fa-github brnd-github" /><a href="https://github.com/davetransom">davetransom</a> or <a href="https://github.com/csharpvitamins">csharpvitamins</a><br />
                  <i className="fa fa-fw fa-lg fa-bitbucket brnd-bitbucket" /><a href="https://bitbucket.org/davetransom/">davetransom</a>
                </p>
              </div>
            </div>
          </div>
      </div>
    </section>
  </div>
