import React, { Fragment } from 'react'

import DaveSection from './dave'
import RinaSection from './rina'

export default () =>
  <Fragment>
    <RinaSection />
    <DaveSection />
    <section>
      <p>Want to know more? Contact <a href="/#dave">Dave</a> or <a href="/#rina">Rina</a> directly.</p>
    </section>
  </Fragment>
